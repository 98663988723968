<template>
    <div id="app">
        <div v-if="oldman">
            <div class="banner w100">
                <div class="box w100" style=" position:absolute; z-index:999">
                    <div class="top">
                        <span>
                            <img v-if="oldman.smallPhoneUrl" :src="oldman.smallPhoneUrl" width="30">
                            <img v-else src="images/logo.png" width="30">
                        </span>
                        <span @click="dialogShow=true">{{ oldman.userName }}&nbsp;</span>&nbsp;
                        <!--<span onclick="window.ts.Toast.text(oldman.userId)">{{ oldman.userName }}&nbsp;</span>&nbsp;-->
                        <span v-if="getdata(oldman.tdActionDataList,'temperature').length>=1">&nbsp;温度{{Math.round(getdata(oldman.tdActionDataList, 'temperature')[0].deviceValue)}}°c |</span>
                        <span></span>
                        <span v-if="getdata(oldman.tdActionDataList,'humidity').length>=1">湿度{{Math.round(getdata(oldman.tdActionDataList, 'humidity')[0].deviceValue.replace('%', ''))}}%</span>
                        <nut-dialog :visible="dialogShow" @ok-btn-click="dialogShow=false" @cancel-btn-click="dialogShow=false" @close="dialogShow=false">
                            <ul>
                                <li v-bind:key="oldmand.userId" v-for="(oldmand,i) in myoldman" @click="setindex(oldmand,i)" style="vertical-align: middle;">
                                    <img v-if="oldmand.smallPhoneUrl" :src="oldmand.smallPhoneUrl" width="30">
                                    <img v-else src="images/logo.png" width="30">
                                    {{ oldmand.userName }}
                                </li>
                            </ul>
                        </nut-dialog>
                    </div>
                </div>
                <div class="box w100">
                    <a href="#"><img src="img/banner.jpg" width="100%"></a>
                </div>
            </div>
            <div class="clear"></div>
            <div class="bk12"></div>
            <div class="box1 w100">
                <span class="b"><img src="img/jc.png"></span>
                <span v-if="logInfo.length > 0">
                    <ul class="import-text2" > {{logInfo[0].logContent}} </ul>
                </span>
                <span v-else-if="oldman.wristband">手环电量{{oldman.power}}%</span>
                <span v-else>传感设备运行正常</span>
                <span v-if="oldman.monitorList && oldman.monitorList[0]" class="cams">
                    <a><img src="img/cam.png"></a>
                    <a :href="oldman.monitorList[0].url">摄像头</a>
                </span>
                <span v-else class="cams">
                    <a><img src="img/cam.png"></a>
                    <a onclick="window.ts.Toast.text('您未安装摄像头！');">摄像头</a>
                </span>
                <span v-if="oldman.wristband" class="cams">
                    <a :href="oldman.wristband"><img src="img/sh.png" width="26"></a>
                    <a>手环</a></span>
                <span v-else class="cams">
                    <a><img src="img/sh.png" width="26"></a>
                    <a onclick="window.ts.Toast.text('您未配备手环！');">手环</a>
                </span>
            </div>

            <div class="clear"></div>
            <div class="bk12"></div>
            <div class="w100 bgf" v-if="oldman.lastLocation">
                <div class="title1" style="margin-top: 5px">当前位置
                    <span style="float:right;color:#999; font-size:0.8rem;font-weight:normal">{{oldman.lastLocation ? localtion[oldman.lastLocation.actionId][0] : ''}}（时长：{{ oldman.lastLocation ? (getDuration(getL(oldman.lastLocation))) : 0 }}）</span>
                </div>
                <div v-if="oldman.xy" @click=jump(oldman.xy) :style="'width:94%;margin:0 auto;background: rgba(255, 255, 255, 0) url('+ oldman.xy +');-moz-background-size: 100% 100%;background-size:100% 100%;height: 120px;'">
                </div>
                <div v-else style="width:94%;margin:0 auto;background: rgba(255, 255, 255, 0) url(img/bg.png);-moz-background-size: 100% 100%;background-size:100% 100%;height: 100px;">
                    <img :style="localtion[oldman.lastLocation.actionId][1]" src="img/dw.png">
                </div>
            </div>
            <div class="clear"></div>
            <div class="bk12"></div>
            <div class="w100 bgf">
                <div class="title1" style="margin-top: 5px"><span style="float:left;">当日情况</span>
                    <span @click="switchPicker('isVisible')">【{{ eDate }}▼】
                        <nut-datepicker :defaultValue="eDate"
                                        :is-show-chinese="true"
                                        :is-visible="isVisible"
                                        @choose="setChooseValue"
                                        style="font-weight:normal; color:#666; font-size:0.8rem;"
                                        title="请选择日期"
                                        type="date">
                        </nut-datepicker>
                    </span>
                    <span onclick="window.location.href='/#/Daylife'" style="float:right;margin-top: -2rem">作息表 ></span>
                </div>
                <div class="list_day" style="margin-top: 5px">
                    <ul>
                        <li>
                            <div class="tit"><span><img src="img/zx.png"></span><span>睡眠</span></div>
                            <div class="txt" v-if="getSleepdata(oldman.tdActionDataList).actionBegTime">
                                <div class="f">时间：<span class="gray">{{ getSleepdata(oldman.tdActionDataList).actionBegTime | getHM }}</span>-<span class="gray">{{ getSleepdata(oldman.tdActionDataList).actionEndTime | getHM }}</span>
                                </div>
                                <div class="f">时长：<span class="green">{{ getSleepdata(oldman.tdActionDataList).actionDuration | getDuration }}</span>
                                </div>
                                <div class="f">起夜：<span class="green">{{ getSleepdata(oldman.tdActionDataList).count }}</span>次
                                </div>
                                <div class="f" v-if="oldman.sleepSum"><span class="gray">卧室时长：{{ oldman.sleepSum | getDuration }}</span>
                                </div>
                            </div>
                        </li>

                        <li style=" float:right;">
                            <div class="tit"><span><img src="img/zf.png"></span><span>厨房</span>
                            </div>
                            <div class="txt">
                                <div class="f" v-bind:key="item.actionDataId" v-for="item in getdata(oldman.tdActionDataList,'cook')">
                                    {{ item.actionBegTime | getCookName }}
                                    {{ item.actionBegTime | getHM }}-{{ item.actionEndTime | getHM }}
                                    (<span class="green">{{ getL(item)|getDurM }}</span>)
                                </div>
                                <div class="f" v-if="oldman.cookSum">
                                    <span class="gray">厨房时长：{{ oldman.cookSum | getDuration }}</span>
                                </div>
                            </div>
                        </li>

                        <li>
                            <div class="tit"><span><img src="img/wc.png"></span><span>外出</span></div>
                            <div class="txt">
                                <div class="f" style="color:#333;">
                                    <span class="green">{{ getdata(oldman.tdActionDataList, 'out').length }}</span>次，共<span class="green">{{ getSumdura(getdata(oldman.tdActionDataList, 'out')) | getDuration }}</span>
                                </div>
                                <div class="f" v-bind:key="item.actionDataId" v-for="item in getdata(oldman.tdActionDataList,'out')">{{ item.actionBegTime | getHM }}-{{ item.actionEndTime | getHM }} (<span class="green">{{ getL(item)|getDuration }}</span>)
                                </div>
                            </div>
                        </li>

                        <li style=" float:right;">
                            <div class="tit"><span><img src="img/eb.png"></span><span>厕所</span></div>
                            <div class="txt">
                                <div class="f">时长3分钟以上：<span class="green">{{ getdata(oldman.tdActionDataList, 'shit').length }}</span>次
                                </div>
                                <div class="f">时长3分钟以下：<span class="green">{{ getdata(oldman.tdActionDataList, 'urine').length }}</span>次
                                </div>
                            </div>
                        </li>

                        <li style="height: 90px;">
                            <div class="tit"><span><img src="img/kt.png"></span><span>客厅</span></div>
                            <div class="txt">
                                <div class="f" style="color:#333;"><span class="green">{{ getSumdura(getdata(oldman.tdActionDataList, 'parlor')) | getDuration }}</span>
                                </div>
                                <div class="f">
                                    <span id="avg" v-once>{{ getAvgParlorData(oldman.userId, getSumdura(getdata(oldman.tdActionDataList, 'parlor'))) }}</span>
                                </div>
                            </div>
                        </li>

                        <li class="two" style=" float:right;height: 90px;">
                            <div class="tit">
                                <span><img src="img/xz.png"></span><span>洗澡</span>
                            </div>
                            <div class="txt">
                                <div class="f" v-bind:key="item.actionDataId" v-for="item in getdata(oldman.tdActionDataList,'bathe')">{{ item.actionBegTime | getHM }}-{{ item.actionEndTime | getHM }} (<span class="green">{{ getL(item)|getDuration }}</span>)
                                </div>
                                <div class="f" v-if="oldman.isHaveBath==false">未安装传感设备</div>
                                <div class="f" v-else-if="oldman.lastAction && getdata(oldman.tdActionDataList,'bathe').length==0">
                                    <span class="red" v-if="DateMinus(oldman.lastAction.actionDate,eDate)>6">{{ DateMinus(oldman.lastAction.actionDate, eDate) }}天</span>
                                    <span class="green" v-else>{{ DateMinus(oldman.lastAction.actionDate, eDate) }}天</span>未洗澡
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import {
        getMyoldmanInfo,
        getAvgParlor,
        getMonitorList,
        getAccessToken,
        getCustLogInfo,
        getLogInfo, getLogInfoStatus1, getWXOpenId
    } from '../api/getMyoldman'

    export default {
        name: 'Home',
        created() {
            this.init();
        },
        mounted() {

        },
        filters: {
            getCookName(datetime) {
                var d = new Date(datetime.replace(/-/g, '/'));
                var h = d.getHours();
                if (h >= 5 && h <= 8)
                    return '早：';
                else if (h >= 10 && h <= 13)
                    return '中：';
                else if (h >= 16 && h <= 19)
                    return '晚：';
                else return ''
            },
            getHM(datetime) {
                if (datetime == null) return ''
                var d = new Date(datetime.replace(/-/g, '/'));
                return d.Format('hh:mm')
            },
            getDurM(second) {
                if (second < 0) return '0分钟';
                var minutes = Math.floor(second / 60);
                return minutes + "分钟";
            },
            getDuration(second) {
                if (second < 0) return '0分钟';
                var hours = Math.floor(second / 3600);
                var minutes = Math.floor((second % 3600) / 60);
                var duration = '';
                if (hours >= 1) {
                    duration = hours + "小时";
                }
                duration = duration + minutes + "分钟";
                return duration;
            }
        },
        data() {
            return {
                localtion: {
                    bathe: ["浴室", 'margin-top: -1%; margin-left: 30%;'],
                    cook: ["厨房", 'margin-top: -1%; margin-left:68%'],
                    dining: ["餐厅", 'margin-top: 14%; margin-left: 68%;'],
                    out: ["外出", 'margin-top: 10%; margin-left: 90%;'],
                    parlor: ["客厅", 'margin-top: 14%; margin-left: 39%;'],
                    reading: ["书房", 'margin-top:10%;margin-left: 40px;'],
                    shit: ["卫生间", 'margin-top: -1%; margin-left: 42%;'],
                    urine: ["卫生间", 'margin-top: -1%; margin-left: 42%;'],
                    sleep: ["卧室", 'margin-top: 8%; margin-left:10%;'],
                },
                eDate: this.$route.query.eDate ? this.$route.query.eDate : new Date().Format("yyyy-MM-dd"),
                dt: -1,
                dialogShow: false,
                myoldman: [{}],
                AccessToken: '',
                isVisible: false,
                oldman: null,
                logInfo: [],
            }
        },
        watch: {
            eDate() {
                this.init()
            }
        },
        methods: {
            jump(url) {
                var u = url.replace('size=526*173', "size=400*400")
                window.location.href = u;
            },
            init() {
                if (this.AccessToken.length < 10) {
                    getAccessToken().then(res => {
                        this.AccessToken = res.data.data.accessToken
                    })
                }
                getWXOpenId(sessionStorage.getItem('openId')).then(res => {
                    sessionStorage.setItem('openId',res.data.data)})
                //getWXOpenId(sessionStorage.getItem('openId')).then(res => {
                    //window.ts.Toast.text("res.data.data:"+res.data.data);
                    //sessionStorage.setItem('openId',res.data.data)})
                window.eDate = this.eDate;
                //window.ts.Toast.text("eDate:"+window.eDate);
                //window.ts.Toast.text("openid:"+sessionStorage.getItem('openId'));
                getMyoldmanInfo(sessionStorage.getItem('openId'), this.eDate).then(res => {
                //getMyoldmanInfo("oVnPq6gg2GRKNMEAaSDGphW8T0e", "2022-07-02").then(res => {
                    this.myoldman = res.data.data
                    //window.ts.Toast.text("this.myoldman:");
                    if (this.myoldman == null) {
                        window.ts.Toast.text("系统未查到您的传感器设备！");
                    }
                    this.oldman = this.myoldman[sessionStorage.getItem("ind") ? sessionStorage.getItem("ind") : 0]
                    window.oldman = this.oldman
                    sessionStorage.setItem("oldman", JSON.stringify(this.oldman))
                    for (var i = 0; i < this.myoldman.length; i++) {
                        this.getmonitorList(i, this.myoldman[i].userId)
                    }
                    //window.ts.Toast.text(this.oldman.userId);
                    getLogInfoStatus1(this.oldman.userId).then(res2 => {
                        this.logInfo = res2.data.data
                        //window.ts.Toast.text("length:"+this.logInfo.length);
                    })
                });

            }
            ,
            setindex(item, i) {
                this.dialogShow = false
                this.oldman = item
                window.ind = i
                sessionStorage.setItem('ind', i)
            },
            getmonitorList(index, userId) {
                if (this.myoldman[index].monitorList == null) {
                    getMonitorList(userId).then(res => {
                        var arr = new Array()
                        var isHaveBath = false
                        var wristband = ""
                        for (var i of res.data.data) {
                            if (i.deviceNameId == 'monitor') {
                                i.url = "https://open.ys7.com/ezopen/h5/live?autoplay=1&audio=1&accessToken=" + this.AccessToken + "&validCode=" + i.deviceValidCode + "&deviceSerial=" + i.userDeviceId + "&channelNo=1";
                                arr.push(i)
                            }
                            if (i.sceneId == 'bathroom') {
                                isHaveBath = true
                            }
                            if (i.deviceNameId == 'wristband') {
                                wristband = "/#/Wristband?deviceId=" + i.userDeviceId
                                window.wristband = i.userDeviceId;
                            }
                        }
                        this.$set(this.myoldman[index], 'isHaveBath', isHaveBath)
                        this.$set(this.myoldman[index], 'monitorList', arr)
                        this.$set(this.myoldman[index], 'wristband', wristband)
                    })
                }
            }
            ,
            getAvgParlorData(userId, tm) {
                if (userId != null) {
                    if (this.dt == -1) {
                        getAvgParlor(userId).then(res => {
                            var dt = this.dt = res.data.data;
                            dt = tm - (dt ? dt : 0);
                            var str = dt > 0 ? '高于平均值' : '低于平均值';
                            document.getElementById("avg").innerHTML = str + this.getDuration(Math.abs(dt))
                        })
                    } else {
                        var dt = this.dt;
                        dt = tm - (dt ? dt : 0);
                        var str = dt > 0 ? '高于平均值' : '低于平均值';
                        document.getElementById("avg").innerHTML = str + this.getDuration(Math.abs(dt))
                    }
                }
            }
            ,
            getDuration(second) {
                if (second < 0) return '0分钟';
                var hours = Math.floor(second / 3600);
                var minutes = Math.floor((second % 3600) / 60);
                var duration = '';
                if (hours >= 1) {
                    duration = hours + "小时";
                }
                duration = duration + minutes + "分钟";
                return duration;
            }
            ,
            getSleepdata(list) {
                var sleepdata = {};
                var i;
                for (i in list) {
                    if (list[i].actionId == 'sleep') {
                        sleepdata = list[i];
                        break;
                    }
                }
                var count = 0;
                for (i in list) {
                    if ((list[i].actionId == 'shit' || list[i].actionId == 'urine') && list[i].actionBegTime >= sleepdata.actionBegTime && list[i].actionBegTime <= sleepdata.actionEndTime) {
                        count++;
                    }
                }
                sleepdata.count = count;
                return sleepdata
            }
            ,
            getdata(list, type) {
                var data = new Array();
                var i, t = -1, h = -1;
                for (i in list) {
                    if ((typeof type == 'string' && list[i].actionId == type) || type.includes(list[i].actionId)) {
                        if ((list[i].actionId == 'shit' || list[i].actionId == 'urine') && list[i].actionDate != this.eDate + ' 00:00:00') {
                            continue;
                        }
                        if (list[i].actionId == 'humidity') {
                            h = i;
                        } else if (list[i].actionId == 'temperature') {
                            t = i;
                        } else {
                            data.push(list[i]);
                        }
                    }
                }
                if (h >= 0) {
                    data.push(list[h])
                }
                if (t >= 0) {
                    data.push(list[t])
                }
                return data
            }
            ,
            getSumdura(data) {
                var i;
                var sum = 0;
                for (i in data) {
                    sum = sum + data[i].actionDuration;
                }
                return sum;
            }
            ,
            DateMinus(date1, date2) {
                var sdate = new Date(date1.replace(/-/g, '/'));
                var now = new Date(date2.replace(/-/g, '/'));
                var days = now.getTime() - sdate.getTime();
                var day = parseInt(days / (1000 * 60 * 60 * 24));
                return day;
            }
            ,
            switchPicker(param) {
                this[`${param}`] = !this[`${param}`];
            }
            ,
            setChooseValue(param) {
                this.eDate = param[3];
            }
            ,
            getL(dt) {
                if (dt.actionDuration == null) {
                    var e = new Date().getTime();
                    var b = new Date(dt.actionBegTime.replace(/-/g, '/')).getTime();
                    return Math.floor((e - b) / 1000);
                } else return dt.actionDuration;
            }
            ,
            addDay() {
                this.eDate = new Date(new Date(this.eDate).getTime() + 24 * 60 * 60 * 1000).Format("yyyy-MM-dd");
            }
            ,
            subDay() {
                this.eDate = new Date(new Date(this.eDate).getTime() - 24 * 60 * 60 * 1000).Format("yyyy-MM-dd");
            }
            ,
        }
    }

</script>